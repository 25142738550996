html {
  height: -webkit-fill-available;
}

body,
#root {
  height: 1px;
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

body {
  font-family: Inter, sans-serif;
}

#root {
  display: flex;
  flex-direction: column;
}

* {
  box-sizing: border-box;
}

@import '~normalize.css/normalize.css';
